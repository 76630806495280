<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">
        <template v-if="expertise.Id">Edit</template>
        <template v-else>Add</template> Expertise
      </h3>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="expertise-name">Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="expertise-name"
              placeholder="Name"
              type="text"
              v-model.trim="expertise.name"
            />
            <span v-if="submitted && v$.expertise.name.required.$invalid" class="error">* required.</span>
            <span
              v-if="v$.expertise.name.maxLength.$invalid"
              class="error"
              >Expertise Name should have at most
              {{ v$.expertise.name.maxLength.$params.max }}.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1" v-if="expertise.type === 'expertise'">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="parent-category-name">Parent</label>
          <div class="form-field">
            <select class="form-control select-box" v-model="expertise.parentId" :disabled="expertise.Id.length > 0">
              <option value="">Select parent category</option>
              <template v-for="(c, i) in categories" :key="i">
              <option :value="c.id">{{c.text}}</option>
              </template>
            </select>
            <span v-if="submitted && v$.expertise.parentId.required.$invalid" class="error">* required.</span>
          </div>
        </div>
      </div>
    </div>
      
    <div class="form-controls">
      <div class="button-row d-flex justify-content-end">
        <button
          class="ml-auto secondary-btn"
          title="Cancel"
          type="button"
          @click.prevent="$emit('close')"
        >
          CANCEL
        </button>
        <button
          class="ml-3 js-btn-next primary-btn"
          type="submit"
          @click.prevent="submitHandler()"
        >
          <template v-if="expertise.Id">Update</template>
          <template v-else>Create</template>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from "@vuelidate/core";
import {
  required,
  maxLength,
  requiredIf,
} from "@vuelidate/validators";
import common from "../mixins/common";
import profilePrefix from "../services/profilePrefix.service";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    editExpertise : {
      type: Object,
      required: false,
    },
    categories : {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  mixins: [common],
  data: () => ({
    submitted: false,
    expertise: {
      Id: "",
      name: "",
      type: "expertise",
      parentId: "",
    },
  }),
  validations() {
    return {
      expertise: {
        name: {
          required,
          maxLength: maxLength(150),
        },
        parentId: {
          required: requiredIf(function () {
            return true;
          }),
        },
      },
    };
  },
  created() {
    const vm = this;
    if(vm.editExpertise && 'Id' in vm.editExpertise && 'name' in vm.editExpertise) {
      vm.expertise = vm.editExpertise;
    }
  },
  methods: {
    async submitHandler() {
      let vm = this;

      vm.submitted = true;
      vm.v$.$touch();

      const isValidate = await vm.v$.$validate();

      if (!isValidate) return;

      vm.expertise.type = 'expertise';

      let formData = vm.expertise;
      
      if (vm.expertise.Id) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await profilePrefix.update(
            formData,
            vm.expertise.Id
          );
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshList");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      } else {
        try {
          delete formData.Id;
          vm.$store.commit("loadingStatus", true);
          const response = await profilePrefix.create(formData);
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshList");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
  },
};
</script>
